import * as React from "react"
//import { Link } from "gatsby"
import { useTranslation } from "react-i18next";

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as privacy from './../css/privacy.module.css';

const Privacy = () => {
  const { t } = useTranslation('privacy');

  return (
  <Layout>
    <Seo title="Cookies and Privacy Policy" />
    <section className={privacy.sectionContent}>
      <h1>{t("heading1")}</h1>
      <h2>{t("whoAreWeH")}</h2>
      <p>{t("whoAreWeP")}</p>
      
      <h2>{t("whatDataH")}</h2>
      <p>{t("whatDataP")}</p>

      <h2>{t("howLongH")}</h2>
      <p>{t("howLongP")}</p>
      
      <h2>{t("dataSharingH")}</h2>
      <p>{t("dataSharingP")}</p>

      <h2>{t("rightsH")}</h2>
      <p>{t("rightsP")}</p>

      <h2>{t("cookiesH")}</h2>
      <p>{t("cookiesP")}</p>
    </section>

  </Layout>
)}

export default Privacy
